import sunglasses from './images/sunglasses.png'
import text from './images/text.jpeg'
import miner from './images/miner.jpeg'
import ni from './images/ni.png'
export default [
    {
        title: 'Sunglasses Placer',
        src: sunglasses,
        text: "We've managed to isolate the technology which let's you place sunglasses on images, now you will be able to place images on sunglasses"
    },
    {
        title: 'Text Rotator',
        src: text,
        text: "Rotate and impress your clients using these effects for your business easily and readibly, by manipulating space and time we've secured rotating capabilites"
    },
    {
        title: 'Manual Proof of Work',
        src: miner,
        text: "AI and computers cannot be trusted to deliever your precious BTC, by using our manual POW system you can solve your own hashes and mine your BTC",
        works: 'Learn More',
        to: "/manual-proof-of-work"
    },
    {
        title: 'NI - Natural Intelligence',
        src: ni,
        text: "How many times has AI let you down? By giving you a secure textbox to write down your own ideas and poetry you can now simply be yourself",
        works: 'Explore NI solutions',
        to: "/natural-intelligence"
    },
]
import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../Navbar';
import './index.css'
import { useSpring, animated } from '@react-spring/web'
import bit from '../images/miners.webp'
import wrong from '../sound/error.mp3'
function MPOW() {
    const [hash, setHash] = useState('')
    const [loading, setLoading] = useState(false)
    const audioRef = useRef(null);
    const [value, setValue] = useState('')
    const [step, setStep] = useState(0)
    const firstSpring = useSpring({
        from: {  opacity: 0 },
        to: { x: 0, opacity: 1 },
        delay: 500
      })
    const generateSHA256Hash = async () => {
        // Generate 32 bytes of random values (256 bits)
        const randomValues = crypto.getRandomValues(new Uint8Array(32));
        
        // Convert to SHA-256 hash
        const hashBuffer = await crypto.subtle.digest('SHA-256', randomValues);
        
        // Convert buffer to hex string
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        
        return hashHex;
      };
    useEffect(() => {
        const int = setInterval(async () => {
            const hash = await generateSHA256Hash()
            setHash(hash)
        },100)
        return () => {
            clearInterval(int)
        }
    },[])
    const onClick = () => {
        setLoading(true)
        audioRef.current.play()
        setValue('')
    }
  return (
    <div className="mpow">
        <audio onEnded={() => setLoading(false)} src={wrong} ref={audioRef}/>
        {
            step === 0 ?
            <animated.h3 style={firstSpring}>
            Welcome to the fucking mine
           </animated.h3>
           : step === 1 ? 
           <animated.h3 style={firstSpring}>
           It ain't pretty here
          </animated.h3> : step === 2 ?
           <animated.div style={firstSpring}>
            <h3>This is your team</h3>
            <img src={bit} />
           </animated.div> 
           
           : <div>
                <p>Now start mining you goddamn retard</p>
                <h6>{hash}</h6>
                <div>
                    <input onChange={e => setValue(e.target.value)} value={value} placeholder="Your solution"/>
                    <button disabled={loading} onClick={onClick}>{loading ? 'Verifying' : 'Mine'}</button>
                </div>
           </div>
        }
 

        {step > 2 ? '': <animated.button style={firstSpring} onClick={() => setStep(step + 1)}>Next</animated.button>}
    </div>

  );
}

export default MPOW;

import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../Navbar';
import './index.css'
import { useSpring, animated } from '@react-spring/web'
import bit from '../sound/bit.mp3'
function NI() {
    const [didPlay, setDidPlay] = useState(false)
    const [value, setValue] = useState('')
    const [timeoutId, setTimeoutId] = useState(0)
    const [refTimeoutId, setRefTimeoutId] = useState(0)
    const [placeholder, setPlaceholder] = useState('Express yourself...')
    const audioRef = useRef(null);
    const onFocus = () => {
        if(!value) {
            setPlaceholder(`Here's a lil somethin somethin to set the vibe`)
            setDidPlay(true)
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            audioRef.current.play();
            const timeout = setTimeout(() => {
                setPlaceholder(`Here's a lil somethin somethin to set the vibe, here at Macrosoft`)
                setTimeout(() => setPlaceholder(`Here's a lil somethin somethin to set the vibe, here at Macrosoft we know how to groove`), 1500)
                setTimeout(() => setPlaceholder(`Here's a lil somethin somethin to set the vibe, here at Macrosoft we know how to groove. We set the tone we're always on the move`), 3000)
                setTimeout(() => setPlaceholder(`Our shlongs are huge and the girls love our cocks`), 7000)
                setTimeout(() => setPlaceholder(`That's how we fucking rule the tech`), 9000)
                setTimeout(() => setPlaceholder(`That's how we fucking rule the tech, we take no hostages we are the best`), 11000)
                setTimeout(() => setPlaceholder(`Fuck microsoft they can suck our cocks`), 14000)
                setTimeout(() => setPlaceholder(`We rule the nation and the whole market's in our butts`), 16000)
                setTimeout(() => setPlaceholder(`Sing it with me come on!`), 19000)
                setTimeout(() => setPlaceholder(`Here at Macrosoft we know how to groove`), 21000)
                const p = setTimeout(() => {
                    if(audioRef && audioRef.current)
                    audioRef.current.pause()
                }, 24000)
                
                setTimeout(() => setPlaceholder(`Sorry I got a little carried away`), 24000)
                setTimeout(() => setPlaceholder(`Express yourself...`), 27000)
            }, 3000)
            setTimeoutId(timeout)
        }
    }
    useEffect(() => {
        // Return a cleanup function
        return () => {
          if(refTimeoutId) clearTimeout(refTimeoutId)
        };
      }, []);
    const firstSpring = useSpring({
        from: {  opacity: 0 },
        to: { x: 0, opacity: 1 },
        delay: 1000
      })
    
    const secondSpring = useSpring({
        from: { opacity: 0 },
        to: { x: 0, opacity: 1 },
        delay: 2000
      })
    const onBlur = () => {
        clearTimeout(timeoutId)
        audioRef.current.pause()
        setPlaceholder('Express yourself...')
    }
    const onChange = e => {
        setValue(e.target.value)
    }
  return (
    <div className="ni">
        <audio ref={audioRef} src={bit} />
        <animated.h2 style={firstSpring}>MACROSOFT NATURAL INTELLIGENCE</animated.h2>
        <animated.div className="ni-stuff" style={secondSpring}>
            <textarea 
                onBlur={onBlur} 
                onFocus={onFocus} 
                onChange={onChange}
                value={value}
                placeholder={placeholder} 
                type="text"  
            />
            <a target="_blank" class="twitter-share-button"
            href={`https://twitter.com/intent/tweet?text=I too have used Natural Intelligence at https://macrosoft.works it truly helped me express myself as a person and find out who's the real me I. AM. COMPLETE.: \n${value}`}
            data-size="large">
        <button>
            Share your original thought with the world
        </button>
        </a>
        </animated.div>


        </div>

  );
}

export default NI;

import React from 'react';
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import NI from './NI';
import Navbar from './Navbar';
import MPOW from './MPOW';
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/natural-intelligence",
    element: <NI />,
  },
  {
    path: "/manual-proof-of-work",
    element: <MPOW />,
  },
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
        <div className="App">
        <Navbar />
   <RouterProvider router={router} />

    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

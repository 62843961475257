import { Link } from "react-router-dom";

function Tech(props) {


  return (
      <div className="Tech">
        <img src={props.src} />
        <h4>{props.title}</h4>
        <p>{props.text}</p>
        {
            props.works ? 
            <Link to={props.to}><button>{props.works}</button></Link> 
            :<button disabled>COMING SOON</button>
        }
        
      </div>
  );
}

export default Tech;

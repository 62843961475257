import React, { useState, useEffect } from 'react';
import logo from './images/logo.png'
import { useSpring, animated } from '@react-spring/web'
import bigimg from './images/big.png'
import { Link } from 'react-router-dom';

function Navbar() {
  const firstSpring = useSpring({
    from: {  opacity: 0 },
    to: { x: 0, opacity: 1 },
    delay: 500
  })

  return (

        <animated.div style={{...firstSpring}} className="heading">
          <img className="logo" src={logo} />
          <img className="big" src={bigimg} />
        </animated.div>
      
  );
}

export default Navbar;

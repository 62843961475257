import React, { useState, useEffect } from 'react';
import './App.css';
import logo from './images/logo.png'
import cto from './images/cto.jpeg'
import sunglasses from './images/sunglasses.png'
import window from './images/window.webp'
import { useSpring, animated } from '@react-spring/web'
import "xp.css/dist/XP.css";
import bigimg from './images/big.png'
import technologies from './technologies';
import Tech from './Tech';
import Navbar from './Navbar';

function App() {
  const [idiot, setIdiot] = useState(0);
  const [bigIndex, setBigIndex] = useState(0)
  const big = ['BIG', 'HEFTY', 'GIGANTIC', 'MASSIVE', 'IMMENSE', 'COLOSSAL', 'VAST', 'SIZABLE', 'FULL', 'FAT', 'LOADED', 'ENORMOUS']
  useEffect(() => {
    setInterval(() => setBigIndex( Math.floor(Math.random() * ((big.length-1) - 0 + 1)) + 0), 2000)
  },[])
  const firstSpring = useSpring({
    from: {  opacity: 0 },
    to: { x: 0, opacity: 1 },
    delay: 500
  })
  const secondSpring = useSpring({
    from: { opacity: 0 },
    to: { x: 0, opacity: 1 },
    delay: 5000
  })

  const thirdSpring = useSpring({
    from: { opacity: 0 },
    to: { x: 0, opacity: 1 },
    delay: 7000
  })

  const fourthSpring = useSpring({
    from: { display: "none" },
    to: { display: "flex" },
    delay: 7000
  })
  const hoverBuy = () => {
   setIdiot(true)
  }
  const leaveBuy = () => {
    setIdiot(false)
  }
  return (
    <div>
      <div className="hero">
      <div>
        <animated.div style={{...firstSpring}} className="content">
          <h2>Unlock your potential with big software </h2>
          <p>Why go small when you can go big? Q5 2024 we're launching our new operating system Window</p>
        </animated.div>
        </div>
      <animated.h1 style={{...secondSpring}}>
        GET READY FOR THE REVOLUTION
      </animated.h1>
    <animated.div className="buy" style={{...thirdSpring}}>
    <h5>Can't go wrong with this fucking name</h5>
    <h5  className="idiot">Only a fucking idiot would <span style={{display: idiot ? 'none' : 'inline'}}>not</span> get this meme</h5>
    <a target="_blank" href="https://sunpump.meme/token/TMjP634zCMd2e3Zjd1oKWpt9BzyvWQAKZ5"><button onMouseEnter={hoverBuy} onMouseLeave={leaveBuy}>BUY MSFT</button></a>
    </animated.div>
    <div>
      </div>

    </div>
    <animated.div className="rest" style={fourthSpring}>
      <h2 className='business'>For Business</h2>
      <animated.div className="technologies">
      {technologies.map(n => <Tech {...n}/>)}
      </animated.div>
    </animated.div>

    <animated.div style={fourthSpring} className="BIG">
      <h1>MACRO={big[bigIndex]}</h1>
      <h5>micro=smol</h5>
    </animated.div>
    <animated.div className="wordsfromcto" style={fourthSpring}>
      <h4>Words from our CTO</h4>
      <div className="CTO">
        <img src={cto} /> <p><span>"Vas is dis shiet? Who dsa fuck put me hier"</span><br></br><br></br> Albert Einstein, Macrosoft CTO - 2024</p>
      </div>
    </animated.div>
    </div>
  );
}

export default App;
